var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('component-layout',{attrs:{"title":'Jobs Grid View'},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"color":"#000"},attrs:{"to":{ path: '/job/create' }}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}]},[_vm._v(" ADD JOB ")])],1)],1)],1)]},proxy:true}])},[_c('JobLayout',{attrs:{"filterCriteriaProps":_vm.filterCriteria,"hasFilter":false},on:{"filter":_vm.filter}},[_c('div',{staticClass:"d-flex justify-end pb-5"},[_c('v-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'jobs' })}}},[_c('v-icon',[_vm._v("mdi-format-list-checkbox")])],1),_c('v-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'jobs.grid-view' })}}},[_c('v-icon',[_vm._v("mdi-grid")])],1)],1),_c('div',{staticClass:"row"},_vm._l((_vm.jobList),function(item){return _c('div',{key:item.id,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/jobs/detail/' + item.id}},[_c('v-card',{staticClass:"card py-5 px-5",attrs:{"elevation":"2"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Job No.")]),_c('div',{staticClass:"detail"},[_vm._v(_vm._s(item.job_no))])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Job Title")]),_c('div',{staticClass:"detail"},[_vm._v(_vm._s(item.job_title))])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Start Date")]),_c('div',{staticClass:"detail start-date"},[_vm._v(_vm._s(item.start_date))])]),(item.job_priority_id)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Job Priority")]),(item.priority)?_c('div',{staticClass:"detail status-preview",style:('background: ' +
                        (item.priority != null
                          ? item.priority.background_color
                          : '') +
                        ';color: ' +
                        (item.priority != null
                          ? item.priority.text_color
                          : ''))},[_vm._v(" "+_vm._s(item.priority ? item.priority.title : "")+" ")]):_c('div')]):_vm._e(),(item.job_type_id)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Job Type")]),(item.job_type)?_c('div',{staticClass:"detail status-preview",style:('background: ' +
                        (item.job_type != null
                          ? item.job_type.background_color
                          : '') +
                        ';color: ' +
                        (item.job_type != null
                          ? item.job_type.text_color
                          : ''))},[_vm._v(" "+_vm._s(item.job_type ? item.job_type.title : "")+" ")]):_c('div')]):_vm._e(),(item.job_status_id)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Job Status")]),(item.job_status)?_c('div',{staticClass:"detail status-preview",style:('background: ' +
                        (item.job_status != null
                          ? item.job_status.background_color
                          : '') +
                        ';color: ' +
                        (item.job_status != null
                          ? item.job_status.text_color
                          : ''))},[_vm._v(" "+_vm._s(item.job_status ? item.job_status.title : "")+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Customer Name")]),_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(item.customer ? item.customer.full_name : "")+" ")])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Customer Number")]),_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(item.customer ? item.customer.full_name : "")+" ")])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"title-1"},[_vm._v("Due Date")]),_c('div',{staticClass:"detail due-date"},[_vm._v(_vm._s(item.due_date))])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"details d-flex"},_vm._l((item.assignees),function(item,i){return _c('div',{key:i,staticClass:"d-flex assigned-user-row"},[_c('div',{staticClass:"assigned-user-circle mr-1 text-white"},[_vm._v(" "+_vm._s(_vm.testimonialUser(item.user.first_name))+_vm._s(_vm.testimonialUser(item.user.last_name))+" ")])])}),0)])])]),_c('div',{staticClass:"col-md-4"},[(item.is_billed == true)?_c('div',{staticClass:"invoice"},[_vm._v(" Invoice: "+_vm._s(item.invoice_total_formatted)+" ")]):_c('div',{staticClass:"invoiced"},[_vm._v("Invoiced")])])])])],1)],1)}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }