<template>
  <div>
    <component-layout :title="'Jobs Grid View'">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list >
            <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ path: '/job/create' }"
            >
              <v-list-item v-ripple="{ center: true }">
                ADD JOB
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>
      </template>

      <JobLayout
        @filter="filter"
        :filterCriteriaProps="filterCriteria"
        :hasFilter="false"
      >
        <div class="d-flex justify-end pb-5">
          <v-btn class="mr-2" @click="$router.push({ name: 'jobs' })">
            <v-icon>mdi-format-list-checkbox</v-icon>
          </v-btn>
          <v-btn class="mr-2" @click="$router.push({ name: 'jobs.grid-view' })">
            <v-icon>mdi-grid</v-icon>
          </v-btn>
        </div>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-6 col-lg-4"
            v-for="item in jobList"
            :key="item.id"
          >
            <router-link
              :to="'/jobs/detail/' + item.id"
              class="text-decoration-none"
            >
              <v-card class="card py-5 px-5" elevation="2">
                <div class="row">
                  <div class="col-md-4">
                    <div class="title-1">Job No.</div>
                    <div class="detail">{{ item.job_no }}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="title-1">Job Title</div>
                    <div class="detail">{{ item.job_title }}</div>
                  </div>
                  <div class="col-md-4">
                    <div class="title-1">Start Date</div>
                    <div class="detail start-date">{{ item.start_date }}</div>
                  </div>
                  <div class="col-md-4" v-if="item.job_priority_id">
                    <div class="title-1">Job Priority</div>
                    <div v-if="item.priority"
                      class="detail status-preview"
                      :style="
                        'background: ' +
                          (item.priority != null
                            ? item.priority.background_color
                            : '') +
                          ';color: ' +
                          (item.priority != null
                            ? item.priority.text_color
                            : '')
                      "
                    >
                      {{ item.priority ? item.priority.title : "" }}
                    </div>
                    <div v-else></div>
                  </div>
                  <div class="col-md-4" v-if="item.job_type_id">
                    <div class="title-1">Job Type</div>
                    <div v-if="item.job_type"
                      class="detail status-preview"
                      :style="
                        'background: ' +
                          (item.job_type != null
                            ? item.job_type.background_color
                            : '') +
                          ';color: ' +
                          (item.job_type != null
                            ? item.job_type.text_color
                            : '')
                      "
                    >
                      {{ item.job_type ? item.job_type.title : "" }}
                    </div>
                    <div v-else></div>
                  </div>
                  <div class="col-md-4" v-if="item.job_status_id">
                    <div class="title-1">Job Status</div>
                    <div v-if="item.job_status"
                      class="detail status-preview"
                      :style="
                        'background: ' +
                          (item.job_status != null
                            ? item.job_status.background_color
                            : '') +
                          ';color: ' +
                          (item.job_status != null
                            ? item.job_status.text_color
                            : '')
                      "
                    >
                      {{ item.job_status ? item.job_status.title : "" }}
                    </div>
                    
                  </div>
                  <div class="col-md-4">
                    <div class="title-1">Customer Name</div>
                    <div class="detail">
                      {{ item.customer ? item.customer.full_name : "" }}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="title-1">Customer Number</div>
                    <div class="detail">
                      {{ item.customer ? item.customer.full_name : "" }}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="title-1">Due Date</div>
                    <div class="detail due-date">{{ item.due_date }}</div>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="details d-flex">
                          <div
                            class="d-flex assigned-user-row"
                            v-for="(item, i) of item.assignees"
                            :key="i"
                          >
                            <div class="assigned-user-circle mr-1 text-white">
                              {{ testimonialUser(item.user.first_name)
                              }}{{ testimonialUser(item.user.last_name) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-4 text-center">
                        <div class="row file-attachment justify-center">
                          <div class="file-attachment-circle">
                            <i class="fa text-white fa-paperclip"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="invoice" v-if="item.is_billed == true">
                      Invoice: {{ item.invoice_total_formatted }}
                    </div>
                    <div class="invoiced" v-else>Invoiced</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </div>
        </div>
      </JobLayout>
    </component-layout>
  </div>
</template>
<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout.vue";
import JobLayout from "@/components/Jobs/Job-Layout.vue";

export default {
  data() {
    return {
      jobList: [],

      filterCriteria: {
        length: 1,
        page: 1,
        q: "",
        job_type: null,
        job_status: null,
        job_priority: null,
        start_date: null,
        end_date: null,
        customer: null,
      },
    };
  },
  components: {
    componentLayout,
    JobLayout,
  },
  mounted() {
    this.getJobList();
  },
  methods: {
    getJobList() {
      util
        .http({ url: "/job/get-all", params: this.filterCriteria })
        .then((res) => {
          this.jobList = res.data.data;
        });
    },
    // assigned users
    chunk(len) {
      let arr = this.$store.state.data.testimonials;
      var chunks = [],
        i = 0,
        n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
      }
      return chunks;
    },
    testimonialUser(val) {
      return val.charAt(0);
    },
    filter(f) {
      this.filterCriteria = f;
    },
  },
  watch: {
    filterCriteria: {
      handler() {
        this.getJobList();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #f6f6f6;
  box-shadow: 0px 0px 10px 0px rgba(74, 74, 74, 0.75) !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(74, 74, 74, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(74, 74, 74, 0.75);
}
.title-1 {
  font-size: 12px;
  font-weight: 700;
  color: #787878;
}
.status-preview {
  background: #010b64;
  /* color: #fff !important; */
  padding: 5px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.detail {
  font-size: 14px;
  font-weight: 900;
  color: #000;
}
.start-date {
  color: #0bd707;
}
.due-date {
  color: #ff0000;
}
.invoice {
  font-size: 14px;
  font-weight: 900;
  color: #ff0000;
}
.invoiced {
  font-size: 14px;
  font-weight: 900;
  color: #0bd707;
}
.assigned-user-circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #1d2b58;
  display: flex;
  justify-content: center;
  align-items: center;
}
.assigned-user-row {
  border-right: 4px solid #e6e6e6;
}
.file-attachment {
  border-right: 4px solid #e6e6e6;
}
.file-attachment-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #1d2b58;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unbilled {
  color: #ff0000;
}
</style>
